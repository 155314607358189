import React from 'react';

import { HeaderContainer } from './HeaderContainer';
import { HeaderLink } from './HeaderLink';
import { HeaderLogo } from './HeaderLogo';
import { HeaderText } from './HeaderText';
import { LinkContainer } from './LinkContainer';
import { SkipToMainContentLink } from './SkipToMainContentLink';

export const Header: React.FunctionComponent<{
	logo: string;
	text?: string;
	hasBackground: boolean;
	links?: { label: string; link: string }[];
}> = (props) => {
	const { logo, text, links } = props;

	return (
		<HeaderContainer hasBackground={props.hasBackground}>
			<SkipToMainContentLink href="#main-content">Skip to main content</SkipToMainContentLink>
			{links && (
				<LinkContainer>
					{links.map(({ label, link }) => (
						<HeaderLink href={link} key={label}>
							{label}
						</HeaderLink>
					))}
				</LinkContainer>
			)}

			{logo && <HeaderLogo src={logo} alt="Corporation logo" />}
			{text && (
				<HeaderText variant="h1" hasBackground={props.hasBackground}>
					{text}
				</HeaderText>
			)}
		</HeaderContainer>
	);
};
