import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { getReceipt, printDocument } from './GetReceipt';
import { StyledAdditionalMessage } from './StyledAdditionalMessage';
import { StyledContainer } from './StyledContainer';
import { StyledImage } from './StyledImage';
import { StyledReturnButton } from './StyledReturnButton';
import { StyledSubtitle } from './StyledSubtitle';
import confirmationImage from '../../../theme/assets/images/payment_confirmation.svg';
import {
	usePaymentParameters,
	useGetIsQuickPayPage,
	useClientBranding,
	useGetIsPayByLink,
} from '../Configuration/hooks';
import { PaymentLayout } from '../Payment/PaymentLayout';

export const PaymentConfirmation: React.FunctionComponent<{ identifier?: string }> = ({ identifier }) => {
	const navigate = useNavigate();
	const { returnUrl, extendedUrl } = usePaymentParameters();
	const intl = useIntl();
	const isQuickPayPage = useGetIsQuickPayPage();
	const branding = useClientBranding();
	const isPayByLink = useGetIsPayByLink();
	const [isPrintMessageVisible, togglePrintMessage] = useState(false);

	const { showPrintReceipt } = branding;

	const [isPrintReceiptLoading, setIsPrintReceiptLoading] = useState(false);
	const receiptMessage = intl.formatMessage({ id: 'payment-confirmation__receipt' });
	const title = intl.formatMessage({ id: 'payment-confirmation__title' });
	const homeBtnLabel = intl.formatMessage({ id: 'button__home' });
	const printReceiptButtonText = intl.formatMessage({ id: 'button__receipt' });

	const onReturn = () => {
		if (isQuickPayPage) {
			window.location.replace('/payments');
			return;
		}

		if (returnUrl) {
			window.location.replace(returnUrl);
			return;
		}

		if (extendedUrl) {
			window.location.replace(extendedUrl);
			return;
		}

		navigate(-1);
	};

	const printReceipt = () => {
		if (identifier) {
			setIsPrintReceiptLoading(true);
			printDocument(getReceipt(identifier), togglePrintMessage).then(() => {
				setIsPrintReceiptLoading(false);
			});
		}
	};

	return (
		<PaymentLayout>
			<StyledContainer>
				<StyledImage src={confirmationImage} alt="Payment confirmation" />
				<StyledSubtitle color="primary" aria-label="title">
					{title}
				</StyledSubtitle>
				<Divider flexItem sx={{ minHeight: 1 }} />
				<StyledAdditionalMessage variant="body2" color="primary" align="center">
					{receiptMessage}
				</StyledAdditionalMessage>
				{identifier && showPrintReceipt && (
					<>
						{isPrintMessageVisible && isPrintReceiptLoading && (
							<Typography color="primary" align="center" sx={(theme) => ({ color: theme.palette.warning.main })}>
								{intl.formatMessage({ id: 'payment-confirmation__receipt_open_message' })}
							</Typography>
						)}
						<Button
							variant="contained"
							color="secondary"
							startIcon={<Icon>receipt</Icon>}
							onClick={printReceipt}
							size="large"
							aria-label={printReceiptButtonText}
							sx={(theme) => ({
								width: '50%',
								textTransform: 'none',
								marginBottom: theme.spacing(3),
								fontSize: 20,
								[theme.breakpoints.down('sm')]: {
									width: '100%',
									padding: `calc(${theme.spacing(1)} - 2px)`,
									fontSize: 18,
								},
							})}
						>
							{printReceiptButtonText}
							{isPrintReceiptLoading && (
								<CircularProgress
									size={20}
									sx={(theme) => ({ marginLeft: `calc(${theme.spacing(1)} - 2px)`, animation: 'none', color: 'white' })}
								/>
							)}
						</Button>
					</>
				)}
				{!isPayByLink && (
					<StyledReturnButton
						variant="contained"
						color="secondary"
						startIcon={<Icon>home</Icon>}
						onClick={onReturn}
						size="large"
						aria-label={homeBtnLabel}
					>
						{homeBtnLabel}
					</StyledReturnButton>
				)}
			</StyledContainer>
		</PaymentLayout>
	);
};
