import { Icon, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import {
	usePaymentParameters,
	useClientBranding,
	useAmountValidation,
	useIsEmailMandatory,
	useConfiguration,
	useIsEmailUneditable,
} from 'components/features/Configuration/hooks';
import { MAX_ALLOWED_BALANCE } from 'components/features/QuickPay/constants';
import { useAmountSeparators } from 'components/features/QuickPay/hooks';
import { validatePaymentAmount } from 'helpers/validatePaymentAmount';

import { getCurrentDate, calculatePaymentsTotalAmount } from '../helpers';
import { CurrencyLabel } from './CurrencyLabel';
import { validateEmailFormat } from './helpers';
import { SummaryTable } from './SummaryTable/SummaryTable';
import { PaymentData } from '../types/payment';

export const PaymentDetails = ({
	onNext,
	loading,
}: {
	onNext: (paymentData: PaymentData) => void;
	loading: boolean;
}) => {
	const {
		data: {
			parameters: { payments },
		},
		config: {
			paymentSystem: { currency, currencySymbol },
		},
		data: { openBankingOnly, identifier },
	} = useConfiguration();

	const [isEmailErrorVisible, setEmailErrorVisibility] = useState(false);
	const paymentParameters = usePaymentParameters();
	const isEmailMandatory = useIsEmailMandatory();
	const isEmailUneditable = useIsEmailUneditable();
	const amountValidation = useAmountValidation();
	const { paymentTerms, backButton } = useClientBranding();
	const matchesMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const [email, setEmail] = useState(paymentParameters.email || '');
	const [amountError, setAmountError] = useState(false);
	const intl = useIntl();
	const isInvalidAmount = useMemo(
		() => amountValidation?.minValue && !validatePaymentAmount(paymentParameters.payments, amountValidation?.minValue),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[paymentParameters.payments, amountValidation?.minValue],
	);

	const { thousandsSeparator, decimalSeparator } = useAmountSeparators();

	const balance = calculatePaymentsTotalAmount(payments);
	const numberFormatParts = new Intl.NumberFormat(undefined, { currency, style: 'currency' }).formatToParts();
	const symbol = currencySymbol || numberFormatParts.find((part) => part.type === 'currency')?.value || '';

	const revenueCodeDescription = openBankingOnly
		? paymentParameters.payments?.find((paymentParam) => paymentParam.revenueCode === openBankingOnly)
				?.referenceNumberDescription
		: '';

	const emailHelperText = isEmailMandatory
		? intl.formatMessage({ id: 'payment-email__mandatory-helperText' })
		: intl.formatMessage({ id: 'payment-email__helperText' });
	const payButtonText = intl.formatMessage({ id: 'button__pay' });
	const summaryTitle = intl.formatMessage({ id: 'payment-summary__title' });

	useEffect(() => {
		if ((amountValidation && balance < amountValidation?.minValue) || balance < 0 || isInvalidAmount) {
			setAmountError(true);
		}
	}, [isInvalidAmount, amountValidation, balance]);

	const onPay = () => {
		// just to be safe, in case user manager to enable the pay button from developer tools
		if (balance > MAX_ALLOWED_BALANCE) {
			return;
		}
		const isEmailValid = validateEmailFormat(email);
		if ((isEmailMandatory && !email.length) || (email.length && !isEmailValid)) {
			setEmailErrorVisibility(true);
		} else {
			onNext({ amount: balance, email });
		}
	};

	const handleBack = () => {
		if (paymentParameters.backUrl) {
			window.location.replace(paymentParameters.backUrl);
		}
	};

	const checkIfValid = ({ value }: NumberFormatValues) => (value.startsWith('0') ? value.indexOf('.') === 1 : true);

	const amountErrorMessage = amountValidation?.message
		? amountValidation.message
		: intl.formatMessage({ id: 'amount-validation_error' });

	return (
		<Box
			sx={(theme) => ({
				padding: theme.spacing(4, 1),
				[theme.breakpoints.up('xs')]: {
					padding: theme.spacing(4, 3),
				},
				[theme.breakpoints.up('sm')]: {
					padding: theme.spacing(6),
				},
			})}
		>
			<Typography
				variant="h1"
				color="primary"
				sx={(theme) => ({
					padding: theme.spacing(0, 6),
					fontWeight: 700,
					fontSize: 24,
					[theme.breakpoints.down('sm')]: {
						paddingLeft: theme.spacing(2),
						padding: theme.spacing(0),
						fontSize: 20,
					},
				})}
			>
				{summaryTitle}
			</Typography>
			<Typography
				variant="subtitle1"
				component="div"
				sx={(theme) => ({
					paddingLeft: theme.spacing(6),
					marginBottom: theme.spacing(3),
					[theme.breakpoints.down('sm')]: {
						marginBottom: theme.spacing(1),
						paddingLeft: theme.spacing(2),
						fontSize: 14,
					},
				})}
			>
				{getCurrentDate()}
			</Typography>
			<SummaryTable />
			<FormControl
				variant="outlined"
				sx={(theme) => ({
					margin: theme.spacing(4, 0),
				})}
			>
				<Typography
					sx={(theme) => ({
						fontSize: '0.7em',
						paddingLeft: theme.spacing(2),
						marginBottom: theme.spacing(1),
						[theme.breakpoints.up('sm')]: {
							paddingLeft: theme.spacing(6),
							fontSize: '1em',
						},
					})}
				>{`Email${isEmailMandatory ? '*' : ' '}`}</Typography>
				<OutlinedInput
					id="email"
					value={email}
					onChange={(evt) => {
						setEmailErrorVisibility(false);
						setEmail(String(evt.target.value).trim());
					}}
					required={isEmailMandatory}
					inputProps={{
						'aria-label': intl.formatMessage({ id: 'payment-email' }),
						...(isEmailMandatory && { 'aria-required': true }),
						autoComplete: 'email',
					}}
					sx={(theme) => ({
						'& input': {
							fontSize: 11,
							[theme.breakpoints.up('sm')]: {
								paddingLeft: theme.spacing(6),
								fontSize: theme.typography.fontSize,
							},
						},
						'& $notchedOutline': {
							[theme.breakpoints.up('sm')]: {
								padding: theme.spacing(0, 5),
							},
						},
					})}
					disabled={Boolean(paymentParameters.email) && isEmailUneditable}
				/>
				{isEmailErrorVisible ? (
					<Typography
						sx={(theme) => ({
							color: theme.palette.error.main,
							marginTop: `calc(${theme.spacing(1)} - 3px)`,
							fontSize: '0.7em',
							[theme.breakpoints.up('sm')]: {
								padding: `${theme.spacing(0)} calc(${theme.spacing(5)} - 6px)`,
								fontSize: '1em',
							},
						})}
						role="alert"
						aria-live="polite"
					>
						{email.length
							? intl.formatMessage({ id: 'payment-email__error' })
							: intl.formatMessage({ id: 'payment-email__error_mandatory' })}
					</Typography>
				) : (
					<FormHelperText
						sx={(theme) => ({
							color: 'black',
							marginTop: `calc(${theme.spacing(1)} - 3px)`,
							fontSize: '0.7em',
							[theme.breakpoints.up('sm')]: {
								padding: `${theme.spacing(0)} calc(${theme.spacing(5)} - 6px)`,
								fontSize: '1em',
							},
						})}
					>
						{emailHelperText}
					</FormHelperText>
				)}
			</FormControl>

			{openBankingOnly && (
				<Box
					sx={(theme) => ({
						textAlign: 'center',
						color: theme.palette.warning.main,
					})}
					id="open-banking"
					component="div"
				>
					{intl.formatMessage({ id: 'stripe-payment__open-banking-only' }, { revenueCodeDescription })}
				</Box>
			)}

			<Box
				component="div"
				sx={(theme) => ({
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					margin: theme.spacing(2, 0),
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
						alignItems: 'stretch',
						justifyContent: 'flex-start',
					},
				})}
			>
				{!matchesMediaQuery && <CurrencyLabel htmlFor="balance-input">{symbol}</CurrencyLabel>}
				<NumericFormat
					value={balance}
					displayType="input"
					{...thousandsSeparator}
					{...decimalSeparator}
					decimalScale={2}
					fixedDecimalScale
					customInput={TextField}
					placeholder="balance"
					name="balance"
					isAllowed={checkIfValid}
					variant="outlined"
					id="balance-input"
					autoComplete="off"
					sx={(theme: Theme) => ({
						maxWidth: '100%',
						width: '90%',
						margin: theme.spacing(0, 2),
						boxShadow: theme.shadows[3],
						borderRadius: '10px',

						'& input': {
							fontSize: 20,
							fontWeight: 700,
							padding: `calc(${theme.spacing(2)} - 6px) ${theme.spacing(2)}`,
							[theme.breakpoints.down('sm')]: {
								fontSize: 22,
								padding: `calc(${theme.spacing(2)} - 6px) ${theme.spacing(6)} calc(${theme.spacing(2)} - 6px) ${theme.spacing(1)}`,
								textAlign: 'center',
							},
						},
						[theme.breakpoints.down('sm')]: {
							margin: theme.spacing(0, 0, 2),
						},
					})}
					readOnly
					InputProps={
						matchesMediaQuery
							? {
									startAdornment: (
										<InputAdornment position="start">
											<CurrencyLabel htmlFor="balance-input">{symbol}</CurrencyLabel>
										</InputAdornment>
									),
									readOnly: true,
								}
							: { readOnly: true }
					}
				/>
				<Button
					variant="contained"
					color="secondary"
					id="paymentButton"
					startIcon={<Icon>credit_card</Icon>}
					onClick={onPay}
					disabled={!balance || !identifier || loading || amountError || balance > MAX_ALLOWED_BALANCE}
					aria-disabled={!balance || loading || amountError || balance > MAX_ALLOWED_BALANCE ? true : false}
					size="large"
					sx={{ textTransform: 'none', fontSize: 18 }}
					aria-label={payButtonText}
					{...(openBankingOnly && { 'aria-describedby': 'open-banking' })}
				>
					{payButtonText}
					{loading && (
						<CircularProgress size={20} sx={(theme) => ({ marginLeft: `calc(${theme.spacing(1)} - 2px)` })} />
					)}
				</Button>
			</Box>

			{amountError && (
				<Typography
					component="p"
					aria-live="polite"
					role="alert"
					sx={(theme) => ({
						alignSelf: 'center',
						fontSize: 14,
						color: theme.palette.error.main,
					})}
				>
					{amountErrorMessage}
				</Typography>
			)}

			{paymentTerms &&
				paymentTerms.map((paragraph, index) => (
					<Typography
						variant="body2"
						color="textSecondary"
						align="left"
						key={index}
						sx={(theme) => ({
							fontSize: 12,
							lineHeight: '1.2',
							'&:first-of-type': {
								marginTop: theme.spacing(10),
							},
							[theme.breakpoints.down('sm')]: {
								fontSize: 10,
							},
						})}
					>
						{paragraph}
					</Typography>
				))}
			{paymentParameters.backUrl && paymentParameters.backUrl.length && (
				<Button
					size="large"
					variant="contained"
					color="primary"
					style={{ backgroundColor: 'transparent' }}
					onClick={handleBack}
					aria-label={backButton ? backButton.text : intl.formatMessage({ id: 'button__back' })}
					sx={(theme) => ({
						textTransform: 'none',
						color: theme.palette.secondary.main,
						border: 'none',
						boxShadow: 'none',

						'&:hover': {
							boxShadow: 'none',
						},
					})}
				>
					{backButton ? backButton.text : intl.formatMessage({ id: 'button__back' })}
				</Button>
			)}
			{balance > MAX_ALLOWED_BALANCE && (
				<Typography
					aria-live="polite"
					role="alert"
					sx={(theme) => ({
						alignSelf: 'center',
						fontSize: 14,
						color: theme.palette.error.main,
					})}
				>
					{intl.formatMessage({ id: 'payment_amount-exceeded-error' })}
				</Typography>
			)}
		</Box>
	);
};
