import { styled } from '@mui/material/styles';

interface HeaderContainerProps {
	hasBackground?: boolean;
}

export const HeaderContainer = styled('header', {
	shouldForwardProp: (prop) => prop !== 'hasBackground',
})<HeaderContainerProps>(({ theme, hasBackground, role }) => ({
	role: role || 'banner',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	flexWrap: 'wrap',
	backgroundColor: hasBackground ? theme.palette.secondary.main : 'transparent',
	padding: theme.spacing(5, 6),
	borderTopLeftRadius: 15,
	borderTopRightRadius: 15,
	borderBottom: !hasBackground ? `1px solid ${theme.palette.divider}` : undefined,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(3, 2),
	},
}));
