import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { PaletteColor } from '@mui/material/styles';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Footer, Header } from 'components/common';

import { useClientBranding } from '../Configuration/hooks';

export const PaymentLayout: FunctionComponent<PropsWithChildren> = ({ children }): JSX.Element => {
	const { logo, header, footer } = useClientBranding();
	const { logoOnly, links: headerLinks, text: headerText, hasBackgroundColor: headerHasBackround } = header;
	const { links: footerLinks, text: footerText, logo: footerLogo, hasBackgroundColor: footerHasBackground } = footer;

	return (
		<Container
			disableGutters
			maxWidth={false}
			sx={(theme) => ({
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: theme.palette.custom
					? theme.palette.custom.main
					: theme.palette.primary[700 as unknown as keyof PaletteColor],
				alignItems: 'center',
				maxWidth: '100%',
				flex: '1 0 auto',
			})}
		>
			<Box
				boxShadow={3}
				sx={(theme) => ({
					backgroundColor: theme.palette.background.paper,
					borderRadius: '15px',
					width: '95%',
					maxWidth: 650,
					margin: theme.spacing(6),
					flex: 1,
					[theme.breakpoints.up('sm')]: {
						width: 550,
					},
					[theme.breakpoints.up('lg')]: {
						width: 650,
					},
				})}
			>
				<Header
					logo={logo}
					text={(!logoOnly && headerText) || undefined}
					hasBackground={headerHasBackround}
					links={headerLinks}
				/>
				<main id="main-content">{children}</main>
			</Box>
			<Footer text={footerText} links={footerLinks} logo={footerLogo} hasBackground={footerHasBackground} />
		</Container>
	);
};
