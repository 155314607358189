import { ApiError } from './ApiError';

export enum RequestMethod {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
}

export enum ContentType {
	JSON = 'application/json',
	FORM_URL_ENCODED = 'application/x-www-form-urlencoded',
	TEXT = 'text/html',
	PDF = 'application/pdf',
}
export type RequestBody = unknown;

export type HeadersType = Record<string, string>;

export type RequestConfig<ReturnRawResponse extends true | false | undefined> = {
	method: RequestMethod;
	body?: RequestBody;
	accessToken?: string | null;
	headers?: HeadersType;
	returnRawResponse: ReturnRawResponse;
};

const getFormattedBody = (body?: RequestBody, headers: HeadersType = {}) => {
	if (body) {
		switch (headers['Content-Type']) {
			case ContentType.JSON:
				return JSON.stringify(body);
			case ContentType.FORM_URL_ENCODED:
				return new URLSearchParams(body as Record<string, string>);
		}
	}
	return null;
};

const getFormattedResponse = (response: Response) => {
	const formattedContentTypeHeader = response.headers.get('content-type')?.split(';')[0];
	switch (formattedContentTypeHeader) {
		case ContentType.JSON:
			return response.json();
		case ContentType.FORM_URL_ENCODED:
			return response.formData();
		case ContentType.TEXT:
			return response.text();
		case ContentType.PDF:
			return response;
	}
};

export async function fetchWrapper<T = unknown>(
	endpoint: string,
	options: Partial<RequestConfig<false | undefined>>,
): Promise<T>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function fetchWrapper<T = unknown>(
	endpoint: string,
	options: Partial<RequestConfig<true>>,
): Promise<Response>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function fetchWrapper<T = unknown, B extends true | false | undefined = false>(
	endpoint: string,
	{
		method = RequestMethod.GET,
		body,
		accessToken = '',
		headers = {},
		returnRawResponse = false,
	}: Partial<RequestConfig<B>> = {},
) {
	const formattedHeaders = { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`, ...headers };

	const config = {
		method,
		headers: formattedHeaders,
		body: getFormattedBody(body, formattedHeaders),
	};

	const response = await fetch(`${window.ENV.API_URL}${endpoint}`, config);
	if (response.ok) {
		if (returnRawResponse === true) {
			return response;
		} else {
			return getFormattedResponse(response);
		}
	} else {
		const body = await response.json();
		throw new ApiError(response.status, response.statusText, body?.code);
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function externalFetchWrapper<T = unknown, B extends true | false | undefined = false>(
	endpoint: string,
	{
		method = RequestMethod.GET,
		body,
		accessToken = '',
		headers = {},
		returnRawResponse = false,
	}: Partial<RequestConfig<B>> = {},
) {
	const formattedHeaders = { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`, ...headers };

	const config = {
		method,
		headers: formattedHeaders,
		body: getFormattedBody(body, formattedHeaders),
	};

	const response = await fetch(endpoint, config);
	if (response.ok) {
		if (returnRawResponse === true) {
			return response;
		} else {
			return getFormattedResponse(response);
		}
	} else {
		const body = await response.json();
		throw new ApiError(response.status, response.statusText, body?.code);
	}
}

export const getFetcher = (url: string) =>
	fetchWrapper(url, {
		method: RequestMethod.GET,
		returnRawResponse: true,
	}).then((res) => res.json());
