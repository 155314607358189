import { styled } from '@mui/material/styles';

export const SkipToMainContentLink = styled('a')(({ theme }) => ({
	position: 'absolute',
	top: -50 /* Moves the link off-screen */,
	left: 0,
	padding: 10,
	zIndex: 1000,
	fontSize: 16,
	borderRadius: 4,
	display: 'block',
	'&:focus': {
		top: 10,
		textDecoration: 'underline',
	},
}));
